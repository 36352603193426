.container {
  background: white;
  padding: 1rem;
}

.graphHeader {
  .titles {
    margin-top: 0.2rem;
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
    flex-flow: row;
  }
}

.dropdownContainer {
  display: flex;
  justify-content: start;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.graph{
  width: 100%;
}

.toolltip {
  border-radius: 0.5rem !important;
  .title{
    padding: 0.5rem;
    width: 100%;
    background: #d0d0d0 !important;
  }
  .minutes{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  }
}


.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
}

.green {
  background-color: #44A048;
}

.gray {
  background: #DDDDDD;
}
