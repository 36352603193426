
.modalContainer{
padding-bottom:    1rem;
}

.graph{
  width: 100%;
}


.legend {
  background: white;
  gap: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin-top: 0.2rem;
  border: #d9d9d9 solid 1px;
  border-radius: 2px;;
  display: flex;
  height: 2rem;
  justify-content: center;
  align-items: center;
  flex-flow: row;

}

.toolltip {
  border-radius: 0.5rem !important;
  .title{
    padding: 0.5rem;
    width: 100%;
    background: #d0d0d0 !important;
  }
  .minutes{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
}

.green {
  background-color: green;
}

.gray {
  background: gray;
}
