body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
  list-style-type: none;
}

h1 {
  font-size: 2em;
}

h3 {
  font-size: 1.3em;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.gray-layout-background {
  background: #f0f2f5;
}

ul {
  margin: 0;
  padding: 0;
}

.anticon-drag {
  position: absolute;
  right: 10px;
  z-index: 9;
  top: 10px;
  color: #001529;
}

.ant-divider-vertical {
  height: 210px;
}

.ant-modal-body .ant-form-item {
  margin-bottom: 10px;
}

.ant-picker {
  margin-right: 10px;
}

.ant-switch {
  width: 95px;
}

#validate_other_theme_image {
  max-width: 25%;
}

.ant-upload-list-item-card-actions {
  display: none !important;
}

.ant-space {
  position: relative;
}

.ant-modal-header {
  padding: 14px 24px;
  margin-bottom: 10px;
}

.ant-modal-body {
  padding: 0 24px;
}

.ant-select {
  width: auto;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.ant-layout-header {
  padding: 0 50px 0 80px;
}

::-webkit-file-upload-button {
  color: black;
  display: inline-block;
  background: transparent;
  border: none;
  padding: 7px 15px;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}

.ant-form input[type='file'] {
  border: none;
}

#validate_other_hardness .ant-tabs-nav {
  margin-left: 25%;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

#validate_other_hardness .ant-tabs-tab {
  background: #43a047;
  padding: 5px 20px;
  color: #fff;
  margin-right: 6px;
}

#validate_other_hardness .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

#validate_other_hardness .ant-tabs-tab:nth-child(2),
#validate_other_hardness .ant-tabs-tab:nth-child(4) {
  background: #d7504c;
}

.ant-modal img {
  max-width: 50%;
}

#validate_other_theme_hard_image {
  max-width: 25%;
}

.ant-tabs-tabpane:nth-child(0) img {
  width: 50%;
}

.ant-modal {
  top: 20px;
}

.ant-table-content img {
  max-width: 100px;
}

/* .editable-row td:nth-child(4) .editable-cell-value-wrap:first-child::after{
  content: 'Content Id';
} */
body::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #1890ff;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #1890ff;
  /* creates padding around scroll thumb */
}

.ant-space-horizontal:nth-child(odd) .ant-space-item-split {
  display: none;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

/* #new_promotion .ant-tabs-tabpane{
  width: auto !important
} */
#validate_other_promotion,
#validate_free {
  max-width: 800px;
}

.bigTable {
  font-size: 12px;
}

.ant-badge:not(.ant-badge-not-a-wrapper) {
}

.ant-badge.ant-badge-rtl:not(.ant-badge-not-a-wrapper) {
  margin-right: 0;
  margin-left: 30px;
}

.head-example {
  /*min-width: 100px;*/
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  right: 10px;
}

.banner-badge .ant-badge-status-dot {
  width: 14px;
  height: 14px;
}

.ant-progress-text {
  font-size: 16px !important;
}

.text-huge {
  font-size: 1.8em;
}

.text-big {
  font-size: 1.4em;
}

.text-small {
  font-size: 0.85em;
}

.text-tiny {
  font-size: 0.7em;
}

.stb-promotions-preview {
  .ant-modal-content {
    padding: 0 !important;
    height: 100%;
    width: 100%;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
}
.promotion-badge {
  .ant-badge-status-dot {
    width: 12px;
    height: 12px;
  }
}



