.legend {
  background: white;
  gap: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin-top: 0.2rem;
  border: #d9d9d9 solid 1px;
  border-radius: 2px;
  display: flex;
  height: 2rem;
  justify-content: center;
  align-items: center;
  flex-flow: row;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.red {
  background-color: red;
}

.yellow {
  background-color: #FAB43B;
}

.green {
  background-color: #44A048;
}

.gray {
  background: #BBBBBB;
}
